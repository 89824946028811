import { useState } from 'react';

import { useLocalization, useToastMessage } from '~src/hooks';
import { usePossibleExpenseTemplates } from '~src/hooks/services';
import { OfferExpense, OfferExpenseInput } from '~src/types';

import { expenseToTableItem, tableItemToExpenseInput } from './convert';
import { OfferExpenseTable } from './OfferExpenseTable';
import { ExpenseTableItem, OfferExpenseTableProps } from './types';

type Props = Omit<OfferExpenseTableProps, 'possibleTemplates' | 'expenses'> & {
  expenses?: Omit<OfferExpense, 'offerID'>[];
  updateExpenses?: (expenses: OfferExpenseInput[]) => Promise<void>;
};

export const OfferExpenseTableExistingOffer = ({
  offerType,
  updateExpenses,
  expenses,
  tableTitle,
  deleteExpenses,
  isLoading,
  allowAdd = true,
}: Props) => {
  const translate = useLocalization();
  const [expenseItemList, setExpenseItemList] = useState<ExpenseTableItem[]>((expenses ?? []).map(expenseToTableItem));

  const { possibleTemplates, isLoading: isLoadingExpenses } = usePossibleExpenseTemplates(offerType);
  const { showMessage } = useToastMessage();

  const handleUpdateExpenses = async (newExpenseList: ExpenseTableItem[]) => {
    setExpenseItemList(newExpenseList);

    await updateExpenses?.(newExpenseList.map(tableItemToExpenseInput));

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const handleDeleteExpenses = async (idList: number[]) => {
    setExpenseItemList(expenseItemList.filter(value => !idList.includes(value.id)));
    await deleteExpenses?.(idList);

    showMessage({ type: 'success', message: translate.SAVED });
  };

  return (
    <OfferExpenseTable
      offerType={offerType}
      isLoading={isLoading || isLoadingExpenses}
      possibleTemplates={(allowAdd ? possibleTemplates : []) ?? []}
      updateExpenses={handleUpdateExpenses}
      expenses={expenseItemList}
      tableTitle={tableTitle}
      deleteExpenses={handleDeleteExpenses}
      allowAdd={allowAdd}
    />
  );
};

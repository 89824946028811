import clsx from 'clsx';

import { Button, Card, Heading, Text, ThumbnailWithUrl } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { formatCurrency } from '~src/utilities/format';

import { attributeRow, attributeTable, highlight, leftAlign, productCard } from './productCard.module.css';

type AttributeTableProps = {
  data: { name: string; value: string | number }[];
};

export type ProductCardProps = {
  attributes?: AttributeTableProps['data'];
  brand?: string;
  imageUrl?: string;
  id: number;
  price?: number;
  subtitle?: string;
  model: string;
  quantity: number;
  handleIncrement?: (id: number) => void;
  handleDecrement?: (id: number) => void;
  handleDelete?: (id: number) => void;
};

export const ProductCardWithQuantity = ({
  attributes,
  brand,
  model,
  imageUrl,
  price,
  subtitle,
  id,
  quantity,
  handleIncrement,
  handleDecrement,
  handleDelete,
}: ProductCardProps) => {
  const translate = useLocalization();

  return (
    <Card className={clsx([productCard, quantity > 0 && highlight])}>
      <ThumbnailWithUrl width={200} imageUrl={imageUrl} alt='product' />

      <div>
        {brand ? <Text className={leftAlign}>{brand}</Text> : null}

        <Heading level={3} className={leftAlign}>
          {model}
        </Heading>

        {subtitle ? <Text className={leftAlign}>{subtitle}</Text> : null}

        <AttributeTable data={attributes ?? []} />
        <Heading level={3}>{formatCurrency(price ?? 0, { addVAT: true, decimals: 0 })}</Heading>

        <div className={attributeRow}>
          {!!handleDecrement && <Button onClick={() => handleDecrement(id)}>-</Button>}
          <div>
            {translate.QUANTITY}: {quantity}
          </div>
          {!!handleIncrement && <Button onClick={() => handleIncrement(id)}>+</Button>}
        </div>
        {!!handleDelete && <Button onClick={() => handleDelete(id)}>{translate.DELETE}</Button>}
      </div>
    </Card>
  );
};

const AttributeTable = ({ data }: AttributeTableProps) =>
  data.length < 1 ? null : (
    <div className={attributeTable}>
      {data.map(({ name, value }) => (
        <div key={name} className={attributeRow}>
          <span>{name}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );

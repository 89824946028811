import { InstallationGuide } from '~src/components/features/installation';
import { useDownloadAllImagesByCode } from '~src/components/features/installation/hooks/useDownloadAllImages';
import { InstallerExpenseInformation, ProductInformation } from '~src/components/features/shared/product';
import { ResidenceInformation } from '~src/components/features/shared/residence';
import { BasicInformation } from '~src/components/features/siteVisit';
import { useLocalization, useLocation } from '~src/hooks';
import { useOfferByInstallationCode } from '~src/hooks/services';
import { formatAddress } from '~src/utilities/format';

import { Button, Card, DisabledTextArea, Spinner } from '../display';
import { DefaultLayout } from '../layouts';

import { displayColumn } from '~src/style/shared.module.css';

import { downloadImagesButton } from './pages.module.css';

export const InstallationGuidePage = () => {
  const translate = useLocalization();
  const { parameters } = useLocation();
  const { accessCode } = parameters;

  const { downloadFile, isLoading: isDownloadingImages } = useDownloadAllImagesByCode(accessCode);

  const { offer, residence, customer, isLoading } = useOfferByInstallationCode(accessCode);

  const { email, name, phoneNumber, notes } = customer ?? {};

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <DefaultLayout type='minimal'>
      <div className={displayColumn}>
        <BasicInformation
          address={formatAddress(residence?.address ?? {})}
          email={email}
          name={name}
          phoneNumber={phoneNumber}
        />

        <Card title={translate.CUSTOMER_NOTES}>
          <DisabledTextArea text={notes ?? ''} />
        </Card>

        <ProductInformation offer={offer} residence={residence} />

        <InstallerExpenseInformation offer={offer} />

        <ResidenceInformation residence={residence} />

        <InstallationGuide offer={offer} residence={residence} />

        {residence?.images?.length > 0 && (
          <Button
            size='large'
            className={downloadImagesButton}
            type='primary'
            onClick={downloadFile}
            loading={isDownloadingImages}
          >
            {translate.DOWNLOAD_ALL_IMAGES}
          </Button>
        )}
      </div>
    </DefaultLayout>
  );
};

import uniqBy from 'lodash/uniqBy';
import { useEffect } from 'react';

import { usePossibleExpenseTemplates } from '~src/hooks/services';
import { OfferExpenseInput } from '~src/types';
import { toOfferExpense } from '~src/utilities/offer';

import { expenseToTableItem } from './convert';
import { OfferExpenseTable } from './OfferExpenseTable';
import { OfferExpenseTableProps } from './types';

type Props = Omit<OfferExpenseTableProps, 'possibleTemplates' | 'expenses'> & {
  expenses?: OfferExpenseInput[];
  updateExpenses?: (expenses: OfferExpenseInput[]) => void;

  addExpenseTemplateIds?: number[];
  removeExpenseTemplateIds?: number[];
};

export const OfferExpenseTableNewOffer = ({
  offerType,
  updateExpenses,
  expenses = [],
  tableTitle,
  deleteExpenses,
  isLoading,
  addExpenseTemplateIds,
  removeExpenseTemplateIds,
}: Props) => {
  const { possibleTemplates, isLoading: isLoadingExpenses } = usePossibleExpenseTemplates(offerType);

  useEffect(
    function addDefaultExpenses() {
      if (!addExpenseTemplateIds?.length && !removeExpenseTemplateIds?.length) {
        return;
      }

      const existingIDs = expenses.map(({ templateID }) => templateID);

      const allExpensesAreAdded = addExpenseTemplateIds?.every(idToAdd => existingIDs.includes(idToAdd));

      const allExpensesAreRemoved = removeExpenseTemplateIds?.every(idToRemove => !existingIDs.includes(idToRemove));

      if (allExpensesAreAdded && allExpensesAreRemoved) {
        return;
      }

      const templatesToAdd = possibleTemplates.filter(value => addExpenseTemplateIds?.includes(value.id));

      const expensesToAdd: OfferExpenseInput[] = templatesToAdd.map(value => ({
        ...toOfferExpense(value),
        price: value.price ?? 0,
        buyPrice: value.buyPrice ?? 0,
        quantity: 1,
        templateID: value.id,
      }));

      const newExpenseList = [...expenses, ...expensesToAdd].filter(
        value => !removeExpenseTemplateIds?.includes(value.templateID ?? 0)
      );

      updateExpenses?.(uniqBy(newExpenseList, 'id'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addExpenseTemplateIds, removeExpenseTemplateIds, possibleTemplates.length]
  );

  const tableExpenses = expenses.map((item, index) => expenseToTableItem({ ...item, id: index }));

  return (
    <OfferExpenseTable
      offerType={offerType}
      isLoading={isLoading || isLoadingExpenses}
      possibleTemplates={possibleTemplates ?? []}
      updateExpenses={async (newExpenses: OfferExpenseInput[]) => updateExpenses?.(newExpenses)}
      expenses={tableExpenses}
      tableTitle={tableTitle}
      deleteExpenses={deleteExpenses}
    />
  );
};

import clsx from 'clsx';
import { useState } from 'react';

import { findOfferValidProductType } from '@bodilenergy/domain';

import { Breadcrumb, Button, Heading, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useNavigation } from '~src/hooks/useNavigation';
import { TranslationRecord } from '~src/localization/translationKeys';
import { Offer, OfferProducts, ResidenceInput } from '~src/types';

import { OfferEditProductsModal } from './OfferEditProductsModal';

import { alignBaseline, clickable, displayColumn, displayRow, spaceBetween } from '~src/style/shared.module.css';

type OfferHeaderProps = {
  offer?: {
    id?: number;
    customer?: Pick<Required<Offer>['customer'], 'firstName' | 'lastName' | 'id'>;
    finalPreview?: Offer['finalPreview'];
    heatPumpOutdoorUnit?: Pick<Required<Offer>['heatPumpOutdoorUnit'], 'model'>;
    preliminaryPreview?: Offer['preliminaryPreview'];
    solarPanel?: Pick<Required<Offer>['solarPanel'], 'model'>;
  } & OfferProducts;
  residence?: ResidenceInput;
};

export const OfferPageHeader = ({ offer, residence }: OfferHeaderProps) => {
  const translate = useLocalization();
  const { navigate, navigateRaw } = useNavigation();

  const [showModal, setShowModal] = useState(false);

  if (!offer) {
    return (
      <div className={displayColumn}>
        <Breadcrumb
          separator='>'
          items={[
            {
              className: clickable,
              onClick: () => {
                navigate('overview');
              },
              title: translate.OVERVIEW_PAGE,
            },
          ]}
        />
      </div>
    );
  }

  const { customer } = offer;

  const { productTypeText, mainProductText } = getProductLabels(offer, translate);

  return (
    <div className={displayColumn}>
      <Breadcrumb
        separator='>'
        items={[
          {
            className: clickable,
            onClick: () => {
              navigate('overview');
            },
            title: translate.OVERVIEW_PAGE,
          },
          {
            className: clickable,
            onClick: () => {
              navigateRaw(`/customer/${customer?.id}`);
            },
            title: `${customer?.firstName} ${customer?.lastName}`,
          },
          { title: `${productTypeText} ${mainProductText}` },
        ]}
      />

      <div className={clsx(displayRow, spaceBetween)}>
        <div className={clsx(displayRow, alignBaseline)}>
          <Heading level={2}>{mainProductText}</Heading>

          <Text>{productTypeText}</Text>
        </div>

        <div className={clsx(displayRow, alignBaseline)}>
          <Button
            type='primary'
            onClick={() => {
              setShowModal(true);
            }}
          >
            {translate.COPY_OFFER}
          </Button>
        </div>
      </div>

      <OfferEditProductsModal isOpen={showModal} offer={offer} residence={residence} toggleOpen={setShowModal} />
    </div>
  );
};

const getProductLabels = (offer: OfferHeaderProps['offer'], translate: TranslationRecord) => {
  const offerType = findOfferValidProductType(offer);

  switch (offerType) {
    case 'assorted': {
      return {
        mainProductText: offer?.assortedProducts?.[0].model,
        productTypeText: translate.ASSORTED_PRODUCTS,
      };
    }

    case 'heatPump': {
      return {
        mainProductText: offer?.heatPumpOutdoorUnit?.model,
        productTypeText: translate.HEAT_PUMP,
      };
    }

    case 'solar': {
      return { mainProductText: offer?.solarPanel?.model, productTypeText: translate.SOLAR_CELL };
    }

    default: {
      return { mainProductText: '', productTypeText: '' };
    }
  }
};

import { create } from 'zustand';

import {
  AssortedProduct,
  Battery,
  HeatPumpIndoorUnit,
  HeatPumpOutdoorUnit,
  Inverter,
  OfferExpenseInput,
  OfferType,
  SolarPanel,
} from '~src/types';

type AssortedProductWithQuantity = AssortedProduct & { quantity: number };

type State = {
  selectedHeatPumpIndoorUnit?: HeatPumpIndoorUnit;
  selectedHeatPumpOutdoorUnit?: HeatPumpOutdoorUnit;

  selectedSolarPanel?: SolarPanel;
  selectedInverter?: Inverter;
  selectedBattery?: Battery;

  selectedOfferExpenses: Array<OfferExpenseInput>;
  selectedOfferType: OfferType;

  selectedAssortedProducts?: Record<string, AssortedProductWithQuantity>;
};

type Actions = {
  selectHeatPumpIndoorUnit: (unit?: HeatPumpIndoorUnit) => void;
  selectHeatPumpOutdoorUnit: (unit?: HeatPumpOutdoorUnit) => void;

  selectSolarPanel: (panel?: SolarPanel) => void;
  selectInverter: (inverter?: Inverter) => void;
  selectBattery: (battery?: Battery) => void;

  setOfferExpenses: (expenseList: Array<OfferExpenseInput>) => void;
  setOfferType: (type: OfferType) => void;

  setAssortedProductQuantity: (entry: AssortedProduct, total?: number, increment?: number) => void;
};

type CreateOfferProductState = State & Actions;

const initialState: State = {
  selectedHeatPumpOutdoorUnit: undefined,

  selectedSolarPanel: undefined,
  selectedInverter: undefined,
  selectedBattery: undefined,

  selectedOfferExpenses: [],
  selectedOfferType: 'heatPump',

  selectedAssortedProducts: undefined,
};

export const useOfferProductState = create<CreateOfferProductState>(set => ({
  ...initialState,

  selectHeatPumpIndoorUnit: (selectedHeatPumpIndoorUnit?: HeatPumpIndoorUnit) => set({ selectedHeatPumpIndoorUnit }),
  selectHeatPumpOutdoorUnit: (selectedHeatPumpOutdoorUnit?: HeatPumpOutdoorUnit) =>
    set({ selectedHeatPumpOutdoorUnit }),

  selectSolarPanel: (selectedSolarPanel?: SolarPanel) => set({ selectedSolarPanel }),
  selectInverter: (selectedInverter?: Inverter) => set({ selectedInverter }),
  selectBattery: (selectedBattery?: Battery) => set({ selectedBattery }),

  setOfferExpenses: (selectedOfferExpenses: Array<OfferExpenseInput>) => set({ selectedOfferExpenses }),
  setOfferType: (selectedOfferType: OfferType) => set({ selectedOfferType }),

  setAssortedProductQuantity: (newEntry, total, increment) => {
    set(current => {
      const { selectedAssortedProducts } = current;

      const { id } = newEntry;

      const existingEntry = selectedAssortedProducts?.[id];

      const { quantity = 0 } = existingEntry ?? {};

      const newQuantity = total ?? quantity + (increment ?? 0);

      if (newQuantity === 0) {
        delete selectedAssortedProducts?.[id];

        return {
          ...current,
          selectedAssortedProducts,
        };
      }

      return {
        ...current,
        selectedAssortedProducts: {
          ...selectedAssortedProducts,
          [id]: { ...newEntry, quantity: Math.max(newQuantity, 0) },
        },
      };
    });
  },
}));

import round from 'lodash/round';
import sumBy from 'lodash/sumBy';

import { OfferExpense, ProductInput } from '~src/types';
import { calculateOfferBuyPrice, calculateOfferPrice } from '~src/utilities/calculationHelper';

type ProductWithBuyPrice = Omit<ProductInput, 'id'>;

export type OfferProductPrices = {
  battery?: ProductWithBuyPrice;
  heatPumpIndoorUnit?: ProductWithBuyPrice;
  heatPumpOutdoorUnit?: ProductWithBuyPrice;
  inverter?: ProductWithBuyPrice;
  solarPanel?: ProductWithBuyPrice;
  expenses?: (ProductWithBuyPrice & Partial<Omit<OfferExpense, 'id'>>)[];
};

export const calculateOfferMargins = (offer?: OfferProductPrices) => {
  if (!offer) {
    return {};
  }

  const discount = calculateExpenseDiscount(offer) ?? {};
  const customerPrice = calculateOfferPrice(offer) - discount;
  const buyPrice = calculateOfferBuyPrice(offer);

  const margin = customerPrice - buyPrice;
  const marginPercentage = round((margin / customerPrice) * 100, 2);

  const discountedCustomerPrice = customerPrice + discount;

  const discountedMargin = discountedCustomerPrice - buyPrice;
  const discountedMarginPercentage = round((discountedMargin / discountedCustomerPrice) * 100, 2);

  return {
    margin,
    marginPercentage,
    discountedMargin,
    discountedMarginPercentage,
  };
};

const calculateExpenseDiscount = (offer: OfferProductPrices) => {
  const { expenses } = offer;

  return sumBy(expenses, ({ price = 0, quantity = 1 }) => (price >= 0 ? 0 : price * quantity));
};

import { useMemo, useState } from 'react';

import { findOfferValidProductType } from '@bodilenergy/domain';

import { EditableTable, SwitchWithLabel } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useArchiveOffer, useSearchOffers } from '~src/hooks/services/useOffer';
import { useNavigation } from '~src/hooks/useNavigation';
import { TranslationRecord } from '~src/localization/translationKeys';
import { Customer, Offer, OfferProducts } from '~src/types';
import { formatAddress, formatCurrency, formatDate, withVAT } from '~src/utilities/format';
import { sortSentOffers } from '~src/utilities/offer';

import { useDownloadCombinedOffersPdf } from '../offer/hooks/useDownloadCombinedOfferpdf';
import { useDownloadOffersDataSheets } from '../offer/hooks/useDownloadOffersDatasheets';
import { useDownloadOffersPdfs } from '../offer/hooks/useDownloadOffersPdfs';

type OfferTableItem = {
  id: number;
  offerId: string;
  product: string;
  productType: string;
  price: string;
  address: string;
  latestSentOffer?: string;
};

type CustomerOfferTableProps = {
  customer?: Pick<Customer, 'id'>;
};

export const CustomerOfferTable = ({ customer }: CustomerOfferTableProps) => {
  const translate = useLocalization();
  const { navigateRaw } = useNavigation();
  const [showArchived, setShowArchived] = useState(false);

  const { offerList, isLoading } = useSearchOffers(customer?.id, undefined, showArchived);

  const { archiveOffer } = useArchiveOffer();

  const { downloadOffersPdfs, isLoading: isLoadingPdf } = useDownloadOffersPdfs();
  const { downloadCombinedOffersPdf, isLoading: isLoadingCombinedPDF } = useDownloadCombinedOffersPdf();
  const { downloadOffersDataSheets, isLoading: isLoadingDataSheets } = useDownloadOffersDataSheets();

  const offerItemList = useMemo(
    () =>
      offerList?.map<OfferTableItem>(offer => {
        const latestSentOfferLabel = getLatestSentOfferLabel(translate, offer);

        const { mainProductText, productTypeText } = getProductLabels(offer as OfferProducts, translate);

        return {
          id: offer.id,
          offerId: offer.tag,
          address: offer.residence?.address ? formatAddress(offer.residence.address) : '',
          price: formatCurrency(withVAT(offer.price), { decimals: 0 }),
          product: mainProductText ?? '',
          productType: productTypeText,
          latestSentOffer: latestSentOfferLabel,
        };
      }) ?? [],
    [offerList, translate]
  );

  if (!customer) {
    return null;
  }

  const handleOnDownloadPdfsClick = async (idList?: number[]) => {
    const relevantOffers = offerList?.filter(offer => idList?.includes(offer.id));

    if (!relevantOffers) {
      return;
    }

    downloadOffersPdfs(relevantOffers, 'preliminary');
  };

  const handleOnDownloadCombinedPdfClick = (idList?: number[]) => {
    const relevantOffers = offerList?.filter(offer => idList?.includes(offer.id));

    if (!relevantOffers) {
      return;
    }

    downloadCombinedOffersPdf(relevantOffers);
  };

  const handleOnDownloadDataSheetsClick = async (idList?: number[]) => {
    const relevantOffers = offerList?.filter(offer => idList?.includes(offer.id));

    if (!relevantOffers) {
      return;
    }

    downloadOffersDataSheets(relevantOffers);
  };

  const handleOnArchiveClick = async (idList?: number[]) =>
    idList?.length ? Promise.all(idList.map(id => archiveOffer(id))) : undefined;

  const handleOnOfferClick = (item: OfferTableItem) => {
    navigateRaw(`/offer/${item.id}`);
  };

  const handleOnCreateOffer = () => {
    navigateRaw(`/create-offer/residence?customerId=${customer.id}`);
  };

  const columnTitleTranslations: Omit<Record<keyof OfferTableItem, string>, 'id'> = {
    address: translate.ADDRESS,
    offerId: `${translate.OFFER} ID`,
    price: translate.PRICE,
    product: translate.PRODUCT,
    productType: translate.PRODUCT_TYPE,
    latestSentOffer: translate.LATEST_SENT_OFFER,
  };

  const tableButtons = [
    {
      buttonText: translate.DOWNLOAD_DATASHEETS,
      loading: isLoadingDataSheets,
      onClick: handleOnDownloadDataSheetsClick,
      requireSelection: true,
    },
    {
      buttonText: translate.DOWNLOAD_OFFER_FILES,
      loading: isLoadingPdf,
      onClick: handleOnDownloadPdfsClick,
      requireSelection: true,
    },
    {
      buttonText: translate.DOWNLOAD_COMBINED_OFFER,
      loading: isLoadingCombinedPDF,
      onClick: handleOnDownloadCombinedPdfClick,
      requireSelection: true,
      tooltip: translate.BEST_OFFER_IS_USED,
    },
    {
      buttonText: translate.ARCHIVE_OFFER,
      onClick: handleOnArchiveClick,
      requireSelection: true,
    },
    {
      buttonText: `+ ${translate.ADD_OFFER}`,
      onClick: handleOnCreateOffer,
      type: 'primary' as const,
    },
  ];

  return (
    <EditableTable<OfferTableItem>
      isLoading={isLoading}
      columnTitleTranslations={columnTitleTranslations}
      itemList={offerItemList}
      onRowClick={handleOnOfferClick}
      buttonList={tableButtons}
      showHeader
      title={`${translate.ALL} ${translate.OFFER}`}
      extraHeaderContent={
        <SwitchWithLabel onChange={() => setShowArchived(!showArchived)} label={translate.SHOW_ARCHIVED} />
      }
    />
  );
};

const getLatestSentOfferLabel = (translate: TranslationRecord, offer?: Pick<Offer, 'sentOffers'>) => {
  const latestSentOffer = sortSentOffers(offer)[0];

  const { sentAt, type } = latestSentOffer ?? {};

  return latestSentOffer
    ? `${type === 'final' ? translate.FINAL_OFFER_SENT : translate.PRELIMINARY_OFFER_SENT} (${
        sentAt ? formatDate(sentAt) : translate.AWAITING
      })`
    : undefined;
};

const getProductLabels = (offer: OfferProducts, translate: TranslationRecord) => {
  const offerType = findOfferValidProductType(offer);

  switch (offerType) {
    case 'assorted': {
      return {
        mainProductText: offer?.assortedProducts?.[0]?.model,
        productTypeText: translate.ASSORTED_PRODUCTS,
      };
    }

    case 'heatPump': {
      return {
        mainProductText: offer?.heatPumpOutdoorUnit?.model,
        productTypeText: translate.HEAT_PUMP,
      };
    }

    case 'solar': {
      return { mainProductText: offer?.solarPanel?.model, productTypeText: translate.SOLAR_CELL };
    }

    default: {
      return { mainProductText: '', productTypeText: '' };
    }
  }
};

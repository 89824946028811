import uniqBy from 'lodash/uniqBy';

import { Card } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useDeleteOfferExpenses, useSetOfferExpenses } from '~src/hooks/services';
import { TranslationRecord } from '~src/localization/translationKeys';
import { Offer, OfferExpenseInput, OfferProducts, toOfferExpenseInput } from '~src/types';
import { findOfferValidProductType } from '~src/utilities/calculationHelper';
import { divideOfferExpenses } from '~src/utilities/offer';

import { OfferExpenseTableExistingOffer } from '../shared/offer/OfferExpenseTable';

type OfferPageExpenseTablesProps = { offer?: Omit<Offer, 'customer' | 'residence'> & OfferProducts };

export const OfferPageExpenseTables = ({ offer }: OfferPageExpenseTablesProps) => {
  const translate = useLocalization();

  const { setOfferExpenses, isLoading: isUpdatingExpenses } = useSetOfferExpenses();
  const { deleteOfferExpenses, isLoading: isDeletingExpenses } = useDeleteOfferExpenses();

  const offerType = findOfferValidProductType(offer);

  if (!offerType || !offer) {
    return null;
  }

  const updateOfferExpenses = async (expenses: OfferExpenseInput[]) => {
    if (!offer?.id) {
      return;
    }

    const expensesAsInput: OfferExpenseInput[] = (offer?.expenses ?? []).map(toOfferExpenseInput);
    const allExpenses = expenses.concat(expensesAsInput);

    await setOfferExpenses(
      offer.id,
      uniqBy(allExpenses, ({ templateID, title }) => `${templateID}-${title}`)
    );
  };

  const dividedExpenses = divideOfferExpenses(offer?.expenses);

  return (
    <Card title={translate.EXPENSES}>
      {Object.entries(dividedExpenses).map(([key, expenses]) => (
        <OfferExpenseTableExistingOffer
          key={key}
          expenses={expenses}
          offerType={offerType}
          updateExpenses={updateOfferExpenses}
          deleteExpenses={async ids => {
            deleteOfferExpenses(Number(offer.id))(ids);
          }}
          tableTitle={getTableTitle(key, translate)}
          isLoading={isUpdatingExpenses || isDeletingExpenses}
          allowAdd
        />
      ))}
    </Card>
  );
};

const getTableTitle = (key: string, translate: TranslationRecord) => {
  switch (key) {
    case 'installation': {
      return translate.INSTALLATION;
    }

    case 'physical': {
      return translate.EXTRA_PARTS;
    }

    case 'discount': {
      return translate.DISCOUNTS;
    }

    case 'nonPhysical':
    default: {
      return translate.ADDED_EXPENSES;
    }
  }
};

import { deepNullToUndefined } from '@bodilenergy/util';

import { useAssortedProductsQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';

export const useAssortedProducts = (ids?: number[]) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useAssortedProductsQuery(
    graphQLClient,
    { ids },
    { onError: useToastMessageErrorHandler() }
  );

  return { assortedProducts: deepNullToUndefined(data?.assortedProducts), isLoading: isFetching };
};

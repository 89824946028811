import { useMemo, useState } from 'react';

import { Button, Form } from '~src/components/display';
import { ResidenceForm } from '~src/components/features/shared/residence';
import { useLocalization } from '~src/hooks';
import { useResidence, useUpsertResidence } from '~src/hooks/services/useResidence';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { mapResidenceToResidenceInput, ResidenceInput } from '~src/types';

import { saveResidenceButton, syncCRMButton } from './customer.module.css';

type CustomerResidenceProps = {
  customerId: number;
  residenceId: number;
  syncCRMContact?: (residenceId?: number) => void;
};

export const CustomerResidence = ({ customerId, residenceId, syncCRMContact }: CustomerResidenceProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const [formUpdated, setFormUpdated] = useState(false);

  const [form] = Form.useForm<ResidenceInput>();

  const { residence: initialResidence } = useResidence(residenceId);

  const residence = useMemo(() => mapResidenceToResidenceInput(initialResidence), [initialResidence]);

  const { upsertResidence } = useUpsertResidence();

  const saveForm = async (newResidence: ResidenceInput) => {
    const updateResidence = {
      ...newResidence,
      address: residence?.address,
      id: residenceId,
    };

    await upsertResidence(customerId, updateResidence);

    showMessage({ type: 'success', message: translate.SAVED });

    setFormUpdated(false);
  };

  return (
    <>
      {!!syncCRMContact && (
        <Button className={syncCRMButton} type='primary' onClick={() => syncCRMContact(residenceId)}>
          {translate.SYNCHRONIZE_CONTACT_WITH_CRM}
        </Button>
      )}

      <ResidenceForm form={form} onSubmit={saveForm} residence={residence} setFormUpdated={setFormUpdated} />

      {formUpdated && (
        <Button className={saveResidenceButton} type='primary' size='large' onClick={form.submit}>
          {translate.UPDATE_RESIDENCE}
        </Button>
      )}
    </>
  );
};

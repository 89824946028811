import { useEffect, useRef, useState } from 'react';

import { AutoComplete, Form, Input, InputRef } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useBbrUnit } from '~src/hooks/services/useBbrUnit';
import { useDawaAddressSuggestions } from '~src/hooks/services/useDawa';
import { Address, DAWAAddressSuggestion, mapResidenceToResidenceInput, Residence, ResidenceInput } from '~src/types';
import { toAddressFromDAWASuggestion } from '~src/utilities/addressHelper';
import { deepNullToUndefined } from '~src/utilities/convert';

type AddressSearchNewProps = {
  className?: string;
  existingAddressSuggestion?: string;
  onSelect: (residence?: ResidenceInput) => void;
};

export const AddressSearchNew = ({ className, existingAddressSuggestion, onSelect }: AddressSearchNewProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const inputRef = useRef<InputRef>(null);

  const [search, setSearch] = useState(existingAddressSuggestion);
  const [suggestedUnit, setSuggestedUnit] = useState<DAWAAddressSuggestion>();

  const { suggestions } = useDawaAddressSuggestions(search ?? '');

  const suggestedAddress = toAddressFromDAWASuggestion(suggestedUnit);
  const { bbrUnit, noBbrData, isFetching: isFetchingBbrData } = useBbrUnit(suggestedAddress);

  useEffect(
    function focusOnPageLoad() {
      if (inputRef.current?.focus) {
        inputRef.current.focus();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef.current]
  );

  useEffect(
    function synchronizeResidenceFromUnit() {
      if (noBbrData) {
        showMessage({ message: translate.NO_BBR_DATA_TO_FETCH, type: 'warning' });
      }

      const unitAddress = deepNullToUndefined(toAddressFromDAWASuggestion(suggestedUnit));

      if (!isFetchingBbrData && !bbrUnit) {
        onSelect({
          area: 0,
          builtYear: 1900,
          areaHeated: 0,
          floors: 1,
          waterConsumption: 'medium',
          energyExpenditure: 0,
          type: 'villa',
          heatDistribution: 'radiator',
          primaryHeating: {
            fuelType: 'gas',
            annualUsage: 0,
          },
          address: toAddress(unitAddress),
        });
        return;
      }

      inputRef?.current?.blur();

      const newResidence = mapBBRUnitToResidence(bbrUnit, unitAddress);

      if (!bbrUnit) {
        return;
      }

      onSelect(mapResidenceToResidenceInput(newResidence));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestedUnit?.data?.id, !!bbrUnit]
  );

  useEffect(
    function autoSelectSingleAddressSuggestion() {
      if (suggestions?.length !== 1) {
        return;
      }

      if (suggestions[0].type === 'vejnavn') {
        return;
      }

      setSuggestedUnit(suggestions[0]);
      inputRef?.current?.blur();
    },
    [suggestions]
  );

  const handleChange = (value: string) => {
    if (value === search) {
      return;
    }

    setSearch(value);
  };

  const handleSelectSuggestion = (suggestionId: string) => {
    const address = suggestions[parseInt(suggestionId, 10)];

    if (!address) {
      return;
    }

    setSearch(address.forslagstekst);

    if (address.type === 'vejnavn') {
      return;
    }

    setSuggestedUnit(address);
  };

  return (
    <Form.Item className={className} label={translate.SEARCH_FOR_ADDRESS}>
      <AutoComplete<string>
        options={suggestions?.map((suggestion: { forslagstekst: string }, index: number) => ({
          label: suggestion.forslagstekst,
          value: index.toString(),
        }))}
        onChange={handleChange}
        onSelect={handleSelectSuggestion}
        value={search}
      >
        <Input.Search ref={inputRef} />
      </AutoComplete>
    </Form.Item>
  );
};

const mapBBRUnitToResidence = (
  bbrUnit: ReturnType<typeof useBbrUnit>['bbrUnit'],
  address?: Partial<Omit<Address, 'id'>>
): Residence =>
  // Ignore use of partial type returned from legacy shared library
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({
    ...bbrUnit,
    address: toAddress(address),
  });

const toAddress = (address?: Omit<Partial<Address>, 'id'>) => ({
  city: address?.city ?? '',
  houseNumber: address?.houseNumber ?? '',
  postalCode: address?.postalCode ?? '',
  street: address?.street ?? '',
  floor: address?.floor,
  door: address?.door,
});

import omit from 'lodash/omit';
import pick from 'lodash/pick';

import type {
  Address as ApiAddress,
  AddressInput as ApiAddressInput,
  ElectricCarInput as ApiElectricCarInput,
  OfferExpenseTemplate as ApiOfferExpenseTemplate,
  Residence as ApiResidence,
  ResidenceHeatingInput as ApiResidenceHeatingInput,
  ResidenceInput as ApiResidenceInput,
  Roof as ApiRoof,
  RoofInput as ApiRoofInput,
} from '../gql';
import { DeepNullToUndefined } from './util';

export type Residence = DeepNullToUndefined<ApiResidence>;
export type ResidenceInput = DeepNullToUndefined<ApiResidenceInput>;

export type Roof = DeepNullToUndefined<ApiRoof>;
export type RoofInput = DeepNullToUndefined<ApiRoofInput>;

export type Address = DeepNullToUndefined<ApiAddress>;
export type AddressInput = DeepNullToUndefined<ApiAddressInput>;

export type ResidenceHeatingInput = DeepNullToUndefined<ApiResidenceHeatingInput>;

export type ElectricCarInput = DeepNullToUndefined<ApiElectricCarInput>;
export type OfferExpenseTemplate = DeepNullToUndefined<ApiOfferExpenseTemplate>;

const RESIDENCE_FORM_BASE_KEYS: readonly (keyof ResidenceInput)[] = [
  'address',
  'addressID',
  'area',
  'areaHeated',
  'areaPartiallyHeated',
  'areaNotHeated',
  'builtYear',
  'consumptionProfile',
  'customerID',
  'electricCar',
  'electricityPrice',
  'energyExpenditure',
  'energyLabel',
  'floors',
  'heatDistribution',
  'heatingCircuits',
  'primaryHeating',
  'radiatorFloorPercentage',
  'renovatedYear',
  'renovatedDegree',
  'residents',
  'type',
  'waterConsumption',
  'roofType',
] as const;

export const mapResidenceToResidenceInput = (
  residence?: Pick<
    Residence,
    (typeof RESIDENCE_FORM_BASE_KEYS)[number] | 'id' | 'roofs' | 'primaryHeating' | 'secondaryHeating' | 'electricCar'
  >
): (ResidenceInput & { id?: number }) | undefined =>
  residence
    ? {
        ...pick(residence, [...RESIDENCE_FORM_BASE_KEYS, 'id', 'roofs']),
        primaryHeating: residence.primaryHeating
          ? omit(residence.primaryHeating, ['id'])
          : { annualUsage: 0, fuelType: '' },
        secondaryHeating: residence.secondaryHeating ? omit(residence.secondaryHeating, ['id']) : undefined,
        electricCar: residence.electricCar ? omit(residence.electricCar, ['id']) : undefined,
      }
    : undefined;

import sumBy from 'lodash/sumBy';

import { Button, Heading } from '~src/components/display';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { useNavigation } from '~src/hooks/useNavigation';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { formatAddress } from '~src/utilities/format';

import { CreateOfferFooter } from '../components/CreateOfferFooter';
import { CreateOfferProductTabs } from '../components/product/CreateOfferProductTabs';
import { Offer, useCreateOfferState } from '../state/createOfferState';

export const CreateOfferProducts = () => {
  const translate = useLocalization();
  const toaster = useToastMessage();

  const { navigate, navigateRaw } = useNavigation();

  const { addOffer, residence, offerList } = useCreateOfferState();

  const {
    selectedOfferExpenses,
    selectedOfferType,
    selectedHeatPumpIndoorUnit,
    selectedHeatPumpOutdoorUnit,
    selectedSolarPanel,
    selectedInverter,
    selectedBattery,
    selectedAssortedProducts,
  } = useOfferProductState();

  const isMissingHeatPumpProducts = !selectedHeatPumpOutdoorUnit;
  const isMissingSolarProducts = !selectedSolarPanel || !selectedInverter;
  const isMissingAssortedProducts =
    sumBy(Object.values(selectedAssortedProducts ?? {}), ({ quantity }) => quantity) <= 0;
  const isMissingOffers = !offerList.length;

  const handleBackClick = () => {
    navigateRaw(`/create-offer/residence/${residence?.id}`);
  };

  const handleCreateOffer = () => {
    if (selectedOfferType === 'solar') {
      handleCreateSolarOffer();
    }

    if (selectedOfferType === 'heatPump') {
      handleCreateHeatPumpOffer();
    }

    if (selectedOfferType === 'assorted') {
      handleCreateAssortedOffer();
    }
  };

  const OFFER_CREATED_MESSAGE = `${translate.OFFER} ${translate.CREATED}`;

  const handleCreateHeatPumpOffer = () => {
    if (isMissingHeatPumpProducts) {
      toaster.showMessage({
        type: 'warning',
        message: `${translate.HEAT_PUMP} ${translate.PRODUCTS_NOT_SELECTED.toLowerCase()}`,
      });
      return;
    }

    const newOffer: Offer = {
      expenses: selectedOfferExpenses,
      ...(selectedHeatPumpIndoorUnit ? { heatPumpIndoorUnit: selectedHeatPumpIndoorUnit } : {}),
      heatPumpOutdoorUnit: selectedHeatPumpOutdoorUnit,
      selected: false,
    };

    addOffer(newOffer);

    toaster.showMessage({ type: 'success', message: OFFER_CREATED_MESSAGE });
  };

  const handleCreateAssortedOffer = () => {
    if (isMissingAssortedProducts) {
      toaster.showMessage({
        type: 'warning',
        message: translate.PRODUCTS_NOT_SELECTED.toLowerCase(),
      });
      return;
    }

    const newOffer: Offer = {
      expenses: selectedOfferExpenses,
      assortedProducts: Object.values(selectedAssortedProducts ?? {}),
      selected: false,
    };

    addOffer(newOffer);

    toaster.showMessage({ type: 'success', message: OFFER_CREATED_MESSAGE });
  };

  const handleCreateSolarOffer = () => {
    if (isMissingSolarProducts) {
      toaster.showMessage({
        type: 'warning',
        message: `${translate.SOLAR_CELL} ${translate.PRODUCTS_NOT_SELECTED.toLowerCase()}`,
      });
      return;
    }

    const newOffer: Offer = {
      expenses: selectedOfferExpenses,
      solarPanel: selectedSolarPanel,
      inverter: selectedInverter,
      battery: selectedBattery,
      selected: false,
    };

    addOffer(newOffer);

    toaster.showMessage({ type: 'success', message: OFFER_CREATED_MESSAGE });
  };

  const onNextButtonClick = () => {
    navigate('createOfferOffers');
  };

  const disableCreateOfferButton =
    (selectedOfferType === 'heatPump' && isMissingHeatPumpProducts) ||
    (selectedOfferType === 'solar' && isMissingSolarProducts) ||
    (selectedOfferType === 'assorted' && isMissingAssortedProducts);

  return (
    <>
      {residence?.address ? <Heading level={3}>{formatAddress(residence.address)}</Heading> : null}

      <CreateOfferProductTabs />

      <CreateOfferFooter>
        <Button onClick={handleBackClick}>{translate.BACK}</Button>

        <Button disabled={disableCreateOfferButton} onClick={handleCreateOffer} type='primary'>
          {translate.ADD_OFFER}
        </Button>

        <Button disabled={isMissingOffers} type='primary' onClick={onNextButtonClick}>
          {translate.CONTINUE}
        </Button>
      </CreateOfferFooter>
    </>
  );
};

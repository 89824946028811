import sumBy from 'lodash/sumBy';

import { Offer, OfferExpense, OfferExpenseTemplate, OfferExpenseType, OfferProducts, ProductInput } from '~src/types';

import { filterFalsy, withVAT } from './calculationHelper';

export const toOfferExpense = (expense?: Partial<OfferExpenseTemplate>) => ({
  ...expense,
  type: (expense?.type?.toLowerCase().includes('installation') ? 'installation' : expense?.type) as OfferExpenseType,
});

export const sortSentOffers = (offer?: Pick<Offer, 'sentOffers'>) =>
  offer?.sentOffers ? [...offer.sentOffers].sort((a, b) => (b.sentAt ?? 0) - (a.sentAt ?? 0) || b.id - a.id) : [];

export const findDefaultExpensesForOffer = <T extends Partial<OfferExpenseTemplate>>(
  offer?: Pick<Offer, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  installationExpenses?: { heatPump: T[]; solar: T[] }
) => {
  if (!offer || !installationExpenses) {
    return [];
  }

  const { heatPump, solar: solarExpenses } = installationExpenses;

  const { heatPumpOutdoorUnit, solarPanel } = offer;

  if (!solarPanel) {
    return heatPump.map(toOfferExpense);
  }

  const solar = findDefaultSolarExpenses(offer, solarExpenses);

  if (!heatPumpOutdoorUnit) {
    return solar.map(toOfferExpense);
  }

  return [...heatPump, ...solar].map(toOfferExpense);
};

const findDefaultSolarExpenses = (
  { solarPanel, battery }: Pick<Offer, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  solarExpenses: Partial<OfferExpenseTemplate>[]
) => {
  const { quantity = 0 } = solarPanel ?? {};

  const installationTypes = new Set(['inverterInstallation', ...(battery ? ['batteryInstallation'] : [])]);

  const sortedSolarExpenses = solarExpenses.sort((a, b) => (b.minimumProducts ?? 0) - (a.minimumProducts ?? 0));

  const solarPanelExpense = sortedSolarExpenses.find(
    ({ minimumProducts = 0, type }) => quantity >= (minimumProducts ?? 0) && type === 'solarPanelInstallation'
  );

  const otherExpenses = solarExpenses.filter(
    expense => !['solarPanelInstallation', 'inverterInstallation', 'batteryInstallation'].includes(expense.type ?? '')
  );

  return [
    ...solarExpenses.filter(({ type }) => installationTypes.has(type ?? '')),
    ...(solarPanelExpense
      ? [
          {
            ...solarPanelExpense,
            price: (solarPanelExpense.price ?? 0) * quantity,
            buyPrice: (solarPanelExpense.buyPrice ?? 0) * quantity,
          },
        ]
      : []),
    ...otherExpenses,
  ];
};

export const formatProductsForOfferInput = (input?: (ProductInput | undefined)[]) => {
  const filtered = filterFalsy(input) as ProductInput[];

  return filtered.map((unit: ProductInput) => ({
    id: unit.id,
    price: unit.price,
    quantity: unit.quantity,
  }));
};

export const divideOfferExpenses = <T extends OfferExpense>(expenses: T[] = []) => {
  type DividedOfferExpenses = {
    installation: T[];
    physical: T[];
    nonPhysical: T[];
    discount: T[];
  };

  return expenses.reduce(
    (result, next): DividedOfferExpenses => {
      const key: keyof DividedOfferExpenses = getDividedExpenseType(next);

      return {
        ...result,
        [key]: [...(result?.[key] ?? []), next],
      };
    },
    {
      installation: [],
      physical: [],
      nonPhysical: [],
      discount: [],
    } as DividedOfferExpenses
  );
};

const getDividedExpenseType = <T extends OfferExpense>(expense: T) => {
  const { type, price } = expense ?? {};

  switch (type) {
    case 'installation': {
      return 'installation';
    }

    case 'physical': {
      return 'physical';
    }

    case 'nonPhysical':
    default: {
      if (price < 0) {
        return 'discount';
      }

      return 'nonPhysical';
    }
  }
};

export const offerPriceWithoutFees = (price: number, offerProducts?: Partial<Pick<OfferProducts, 'expenses'>>) =>
  price -
  sumBy(
    offerProducts?.expenses?.filter(({ includedByDefault }) => includedByDefault === true),
    ({ price: expensePrice }) => withVAT(expensePrice)
  );

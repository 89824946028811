import { Button } from '~src/components/display';
import { useLocalization, useNavigation } from '~src/hooks';
import { TranslationRecord } from '~src/localization/translationKeys';
import { Offer } from '~src/types';
import { filterFalsy, findOfferMainProductType } from '~src/utilities/calculationHelper';
import { formatAddress, formatDate, SharedAddress } from '~src/utilities/format';

import { OverviewTableRecord } from '../types';

export const useFormatOverviewTableData = (offers: Array<Offer> | undefined = []): OverviewTableRecord[] => {
  const translate = useLocalization();
  const { navigateRaw } = useNavigation();

  return offers.map(offer => {
    const { id, tag, customer, residence } = offer;
    const { firstName, lastName, email, phoneNumber, leadOrigin, createdAt, id: customerID } = customer ?? {};
    const { address, id: residenceID } = residence ?? {};

    const name = filterFalsy([firstName, lastName]).join(' ');

    const phoneFormatted = !phoneNumber || phoneNumber?.includes('+45') ? phoneNumber : `+45 ${phoneNumber}`;

    return {
      id,
      key: tag,
      name,
      email: email ?? '',
      phoneNumber: phoneFormatted,
      address: address ? formatAddress(address as SharedAddress) : '',
      leadOrigin: leadOrigin ? `${leadOrigin}` : '',
      productType: getProductLabel(offer, translate),
      actions: (
        <Button
          onClick={event => {
            event.stopPropagation();
            navigateRaw(`/customer/${customerID}`);
          }}
        >
          {translate.GO_TO_CUSTOMER}
        </Button>
      ),
      customerCreatedAt: createdAt ? formatDate(createdAt) : '',
      residenceID,
    };
  });
};

const getProductLabel = (offer: Offer, translate: TranslationRecord) => {
  const offerType = findOfferMainProductType(offer);

  switch (offerType) {
    case 'assorted': {
      return translate.ASSORTED_PRODUCTS;
    }

    case 'heatPump': {
      return translate.HEAT_PUMP;
    }

    case 'solar': {
      return translate.SOLAR_CELL;
    }

    default: {
      return '';
    }
  }
};

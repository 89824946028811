import { AssortedProductCard } from '~src/components/display';
import { ProductPagination } from '~src/components/display/Pagination/ProductPagination';
import { useOfferProductState } from '~src/hooks';
import { useAssortedProducts } from '~src/hooks/services/products/assortedProduct';

import { displayColumn } from '~src/style/shared.module.css';

export const AssortedProductSelector = () => {
  const { selectedAssortedProducts, setAssortedProductQuantity } = useOfferProductState();

  const { assortedProducts } = useAssortedProducts();

  return (
    <div className={displayColumn}>
      <ProductPagination
        itemList={assortedProducts?.map(item => {
          const { quantity = 0 } = selectedAssortedProducts?.[item.id] ?? {};

          return (
            <AssortedProductCard
              key={`${item.brand}-${item.model}`}
              handleIncrement={() => setAssortedProductQuantity(item, undefined, 1)}
              handleDecrement={() => setAssortedProductQuantity(item, undefined, -1)}
              handleDelete={() => setAssortedProductQuantity(item, 0)}
              product={item}
              quantity={quantity}
            />
          );
        })}
      />
    </div>
  );
};

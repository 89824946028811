import { findOfferValidProductType } from '@bodilenergy/domain';

import { Offer, Residence } from '~src/types';

import { useInstallationGuide } from './hooks/useInstallationGuide';
import { InstallationGuideElectricity } from './InstallationGuideElectricity';
import { InstallationGuideElectricitySolar } from './InstallationGuideElectricitySolar';
import { InstallationGuideFloorPlan } from './InstallationGuideFloorPlan';
import { InstallationGuideHeatSource } from './InstallationGuideHeatSource';
import { InstallationGuideIndoor } from './InstallationGuideIndoor';
import { InstallationGuideInverterBattery } from './InstallationGuideInverterBattery';
import { InstallationGuideOther } from './InstallationGuideOther';
import { InstallationGuideOutdoor } from './InstallationGuideOutdoor';
import { InstallationGuidePiping } from './InstallationGuidePiping';
import { InstallerGuidePurchaseInformation } from './InstallationGuidePurchaseInformation';
import { InstallationGuideRadiators } from './InstallationGuideRadiators';
import { InstallationGuideRoof } from './InstallationGuideRoof';
import { InstallationGuideSolarPanel } from './InstallationGuideSolarPanel';

type QuestionInformationProps = {
  offer?: Pick<Offer, 'heatPumpOutdoorUnit' | 'tag'>;
  residence?: Residence;
};

export const InstallationGuide = ({ offer, residence }: QuestionInformationProps) => {
  const offerType = findOfferValidProductType(offer);

  return (
    <>
      {offerType === 'heatPump' && <InstallationGuideHeatPump offer={offer} residence={residence} />}

      {offerType === 'solar' && <InstallationGuideSolar residence={residence} />}

      {offerType === 'assorted' && (
        <>
          <InstallationGuideHeatPump offer={offer} residence={residence} />

          <InstallationGuideSolar residence={residence} />
        </>
      )}
    </>
  );
};

const InstallationGuideHeatPump = ({ offer, residence }: QuestionInformationProps) => {
  const { answerMap, imageMap } = useInstallationGuide(residence);

  return (
    <div>
      <InstallerGuidePurchaseInformation brand={offer?.heatPumpOutdoorUnit?.brand} tag={offer?.tag} />

      <InstallationGuideOutdoor imageList={imageMap.outdoorPlacement} answerMap={answerMap} />

      <InstallationGuideRadiators imageList={imageMap.radiators} answerMap={answerMap} />

      <InstallationGuideHeatSource imageList={imageMap.heatSource} answerMap={answerMap} />

      <InstallationGuidePiping imageList={imageMap.piping} answerMap={answerMap} />

      <InstallationGuideIndoor imageList={imageMap.indoorPlacement} answerMap={answerMap} />

      <InstallationGuideElectricity imageList={imageMap.electricity} answerMap={answerMap} />

      <InstallationGuideFloorPlan imageList={imageMap.floorPlan} answerMap={answerMap} />

      <InstallationGuideOther imageList={imageMap.other} answerMap={answerMap} />
    </div>
  );
};

const InstallationGuideSolar = ({ residence }: Pick<QuestionInformationProps, 'residence'>) => {
  const { answerMap, imageMap } = useInstallationGuide(residence);

  return (
    <div>
      <InstallationGuideSolarPanel imageList={imageMap.solarPlacement} answerMap={answerMap} />

      <InstallationGuideInverterBattery imageList={imageMap.inverterAndBatteryPlacement} answerMap={answerMap} />

      <InstallationGuideRoof residence={residence} imageList={imageMap.roofConstruction} answerMap={answerMap} />

      <InstallationGuideElectricitySolar imageList={imageMap.electricity} answerMap={answerMap} />

      <InstallationGuideFloorPlan imageList={imageMap.floorPlan} answerMap={answerMap} />

      <InstallationGuideOther imageList={imageMap.other} answerMap={answerMap} />
    </div>
  );
};

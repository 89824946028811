import groupBy from 'lodash/groupBy';
import max from 'lodash/max';

import { OfferExpenseInput } from '~src/types';
import { withVAT } from '~src/utilities/format';

import { ExpenseTableItem, TableExpense } from './types';

export const expenseToTableItem = ({
  id,
  templateID,
  notes,
  quantity,
  price,
  buyPrice,
  title,
  type,
}: Omit<TableExpense, 'id'> & { id: number }): ExpenseTableItem => ({
  id,
  templateID,
  title: title ?? '',
  price,
  buyPrice,
  priceWithVAT: withVAT(price),
  quantity: quantity ?? 1,
  notes: notes ?? '',
  type,
});

export const getExpenseItemSorter = (locale: string) => (a: ExpenseTableItem, b: ExpenseTableItem) =>
  a.title.localeCompare(b.title, locale, { sensitivity: 'base' });

export const tableItemToExpenseInput = ({
  notes,
  price,
  buyPrice,
  quantity,
  templateID,
  title,
  type,
}: ExpenseTableItem): OfferExpenseInput => ({
  templateID,
  price: Number(price) || 0,
  buyPrice: Number(buyPrice) || 0,
  notes,
  quantity: Number(quantity),
  title,
  type,
});

type TableExpenseWithID = Omit<TableExpense, 'id'> & { id: number };

export const expensesToList = (expenses?: TableExpense[]) => {
  const groupedExpenses = groupBy(expenses, expense => !!expense.id);

  const { true: expensesWithID = [], false: expensesWithoutID = [] } = groupedExpenses;

  const highestID = max(expensesWithID?.map(expense => expense.id));

  const expensesWithAddedID = expensesWithoutID.map((expense, index) => ({
    ...expense,
    id: (highestID ?? 0) + index + 1,
  }));

  return [
    ...(expensesWithID as TableExpenseWithID[]).map(expenseToTableItem),
    ...expensesWithAddedID.map(expenseToTableItem),
  ];
};

import { Card } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { TranslationRecord } from '~src/localization/translationKeys';
import { OfferType } from '~src/types';

import { Offer, useCreateOfferState } from '../../state/createOfferState';
import { OfferDetails } from './OfferDetails';

type OfferListProps = {
  type: OfferType;
};

export const OfferList = ({ type }: OfferListProps) => {
  const translate = useLocalization();
  const { offerList } = useCreateOfferState();

  const title = getTitle(type, translate);

  const offersOfMatchingType = getOffersOfMatchingType(type, offerList);

  return (
    <Card title={`${title} ${translate.OFFER}`}>
      {offersOfMatchingType?.map(({ id }) => <OfferDetails key={id} offerId={id ?? 0} />)}
    </Card>
  );
};

const getTitle = (type: OfferType, translate: TranslationRecord) => {
  if (type === 'heatPump') {
    return translate.HEAT_PUMP;
  }

  return type === 'solar' ? translate.SOLAR_CELL : translate.ASSORTED_PRODUCTS;
};

const getOffersOfMatchingType = (type: OfferListProps['type'], offerList?: Array<Offer>) =>
  offerList?.filter(offer => {
    if (!offer) {
      return false;
    }

    if (type === 'heatPump') {
      return !!offer.heatPumpOutdoorUnit;
    }

    if (type === 'assorted') {
      return offer.assortedProducts?.length;
    }

    return !!offer.solarPanel;
  });

import { EditableTable } from '~src/components/display';
import { HeatPumpOutdoorUnit, OfferProductType } from '~src/gql';
import { useLocalization, useToastMessage } from '~src/hooks';
import { TranslationRecord } from '~src/localization/translationKeys';
import { Offer, SolarPanel } from '~src/types';
import { filterFalsy, withVAT } from '~src/utilities/calculationHelper';

import { displayColumn } from '~src/style/shared.module.css';

type OfferWithProducts = Pick<
  Offer,
  'heatPumpIndoorUnit' | 'heatPumpOutdoorUnit' | 'inverter' | 'solarPanel' | 'battery' | 'assortedProducts'
>;

type ProductExpense = Pick<HeatPumpOutdoorUnit, 'id' | 'brand' | 'model' | 'price' | 'buyPrice' | 'quantity'> & {
  type: OfferProductType;
  displayType: string;
  priceWithVAT?: number;
};

const EDITABLE_COLUMNS: (keyof ProductExpense)[] = ['price'];

type OfferExpenseTableProps = {
  offer?: OfferWithProducts;
  loading?: boolean;
  title?: string;
  updatePrice: (unitId: number, price: number, type: OfferProductType) => Promise<void> | void;
};

export const OfferProductTable = ({ offer, loading = false, title, updatePrice }: OfferExpenseTableProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const expenses = offerToProductExpenses(translate, offer);

  const columnTitleTranslations: Partial<Record<keyof ProductExpense, string>> = {
    brand: translate.BRAND,
    model: translate.MODEL,
    displayType: translate.TYPE,
    buyPrice: translate.BUY_PRICE,
    price: translate.PRICE,
    priceWithVAT: translate.PRICE_WITH_VAT,
    quantity: translate.QUANTITY,
  };

  const handleUpdateExpenses = async (item: ProductExpense) => {
    await updatePrice(item.id, Number(item?.price ?? 0), item.type);

    showMessage({ type: 'success', message: translate.SAVED });
  };

  return (
    <div className={displayColumn}>
      <EditableTable<ProductExpense>
        columnTitleTranslations={columnTitleTranslations}
        editableList={EDITABLE_COLUMNS}
        itemList={expenses}
        onChangeItem={handleUpdateExpenses}
        isLoading={loading}
        showHeader
        title={title}
        rowKey='model'
      />
    </div>
  );
};

const offerToProductExpenses = (translations: TranslationRecord, offer?: OfferWithProducts): ProductExpense[] => {
  if (!offer) {
    return [];
  }

  const { heatPumpIndoorUnit, heatPumpOutdoorUnit, solarPanel, inverter, battery, assortedProducts } = offer;

  const expenses: (ProductExpense | undefined)[] = [
    toProductExpense('heatPumpOutdoorUnit', translations.OUTSIDE_PART, translations, heatPumpOutdoorUnit),
    toProductExpense('heatPumpIndoorUnit', translations.INSIDE_PART, translations, heatPumpIndoorUnit),
    toProductExpense('solarPanel', translations.SOLAR_CELL, translations, solarPanel),
    toProductExpense('inverter', translations.INVERTER, translations, inverter),
    toProductExpense('battery', translations.BATTERY, translations, battery),
    ...filterFalsy(
      assortedProducts?.map(product =>
        toProductExpense('assorted', translations.ASSORTED_PRODUCTS, translations, product)
      )
    ),
  ];

  return filterFalsy(expenses);
};

const toProductExpense = (
  type: OfferProductType,
  displayType: string,
  translations: TranslationRecord,
  entry?: Partial<Pick<SolarPanel, 'quantity'>> & Pick<SolarPanel, 'id' | 'brand' | 'model' | 'price' | 'enabled'>
): ProductExpense | undefined =>
  entry
    ? {
        ...entry,
        priceWithVAT: withVAT(entry.price),
        type,
        displayType,
        model: entry.enabled || type === 'assorted' ? entry.model : `${entry.model} (${translations.DISABLED})`,
        quantity: entry.quantity ?? 1,
      }
    : undefined;

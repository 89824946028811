import { OfferInformationCard } from '~src/components/display';
import { TagsOutlined } from '~src/components/display/Icons';
import { InfoBox } from '~src/components/display/InfoBox';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { DefaultLayout } from '~src/components/layouts';
import { useLocalization } from '~src/hooks';
import { useUpdateOfferProductPrice } from '~src/hooks/services';
import {
  Customer,
  mapCustomerToCustomerInput,
  mapResidenceToResidenceInput,
  Offer,
  OfferProducts,
  OfferProductType,
  Residence,
} from '~src/types';
import {
  calculateOfferPrice,
  findOfferValidProductType,
  formatCurrency,
  withVAT,
} from '~src/utilities/calculationHelper';
import { offerPriceWithoutFees } from '~src/utilities/offer';

import { CustomerNotes } from '../shared/customer';
import { OfferProductTable } from '../shared/offer/OfferProductTable';
import { OfferInstallerLinks } from './OfferInstallerLinks';
import { OfferNotes } from './OfferNotes';
import { OfferPageExpenseTables } from './OfferPageExpenseTables';
import { OfferPageHeader } from './OfferPageHeader';
import { OfferReservations } from './OfferReservations';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { assortedOfferPageContent, noteContainer } from './offerPageContent.module.css';

type AssortedOfferPageContentProps = {
  offer?: Omit<Offer, 'customer' | 'residence'> & OfferProducts;
  residence?: Residence;
  customer?: Customer;
};

export const AssortedOfferPageContent = ({ offer, residence, customer }: AssortedOfferPageContentProps) => {
  const translate = useLocalization();

  const residenceInput = mapResidenceToResidenceInput(residence);
  const customerInput = mapCustomerToCustomerInput(customer);

  const { updateOfferProductPrice, isLoading: isUpdatingProductPrices } = useUpdateOfferProductPrice();

  const offerType = findOfferValidProductType(offer);

  const price = withVAT(calculateOfferPrice(offer));
  const priceWithoutFees = offerPriceWithoutFees(price, offer);

  if (!offerType || !offer?.id) {
    return null;
  }

  const updateProductPrice = async (unitId: number, productPrice: number, type: OfferProductType) => {
    if (!offer?.id) {
      return;
    }

    await updateOfferProductPrice({ offerId: offer.id, unitId, price: productPrice, type });
  };

  return (
    <DefaultLayout>
      <OfferPageHeader offer={offer} residence={residenceInput} />

      <div className={assortedOfferPageContent}>
        <div className={displayColumn}>
          <OfferInformationCard offer={offer} address={residence?.address} />

          <div className={displayRow}>
            <InfoBox
              title={translate.PRICE}
              icon={<TagsOutlined />}
              value={formatCurrency(price ?? 0, { decimals: 0 })}
              subtitle={`(${translate.WITHOUT_FEES}: ${formatCurrency(priceWithoutFees, { decimals: 0 })})`}
            />

            <OfferProfitMargins offer={offer} />
          </div>

          <OfferInstallerLinks offer={offer} />

          <OfferProductTable
            offer={offer}
            updatePrice={updateProductPrice}
            title={translate.PRODUCTS}
            loading={isUpdatingProductPrices}
          />

          <OfferPageExpenseTables offer={offer} />

          <div className={noteContainer}>
            <OfferReservations offer={offer} />

            <OfferNotes offer={offer} />

            <CustomerNotes customer={customerInput} rows={10} title={translate.CUSTOMER_NOTES} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

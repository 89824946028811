import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { getProviderTranslation, getSolarBufferRequiredTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideSolarPanel = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_SOLAR}
      </Heading>

      <div className={informationColumn}>
        {answerMap?.solarEntryPossible?.answer === 'false' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_SOLAR_MATERIALS} value={translate.NO} />
        )}

        {answerMap?.easyAvailableForScaffolding?.answer === 'false' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_SOLAR_SCAFFOLDING} value={translate.NO} />
        )}

        {!!answerMap?.providerForFoundationSolar?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_SOLAR_FOUNDATION_PROVIDER}
            value={getProviderTranslation(translate, answerMap?.providerForFoundationSolar?.answer)}
          />
        )}

        {!!answerMap?.bufferRequiredSolar?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_SOLAR_CABLE_LOCATION}
            value={getSolarBufferRequiredTranslation(translate, answerMap?.bufferRequiredSolar?.answer)}
          />
        )}

        {!!answerMap?.solarPlacementNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.solarPlacementNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};

import { useState } from 'react';

import { EditableTable } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useLocaleState } from '~src/state';
import { withVAT } from '~src/utilities/format';

import { getExpenseItemSorter } from './convert';
import { CreateOfferExpenseModal } from './CreateOfferExpenseModal';
import { ExpenseTableItem, OfferExpenseTableProps } from './types';
import { useColumnTitleTranslations } from './useColumnTitleTranslations';

import { displayColumn } from '~src/style/shared.module.css';

const EDITABLE_COLUMNS: (keyof ExpenseTableItem)[] = ['notes', 'price', 'quantity'];

type Props = OfferExpenseTableProps & {
  expenses?: ExpenseTableItem[];
  updateExpenses?: (expenses: ExpenseTableItem[]) => Promise<void>;
};

export const OfferExpenseTable = ({
  expenses = [],
  isLoading = false,
  tableTitle,
  updateExpenses,
  deleteExpenses,
  possibleTemplates,
  allowAdd = true,
}: Props) => {
  const translate = useLocalization();
  const [locale] = useLocaleState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { columnTitleTranslations } = useColumnTitleTranslations();

  const handleChangeItem = (expense: ExpenseTableItem) => {
    const newExpense: ExpenseTableItem = {
      ...expense,
      quantity: Math.max(expense.quantity, 1),
      priceWithVAT: withVAT(expense.price),
    };

    const newList = [...expenses.filter(({ id }) => id !== expense.id), newExpense];
    updateExpenses?.(newList);
  };

  const handleDeleteItems = async (idList?: number[]) => {
    if (!idList?.length) {
      return;
    }

    if (deleteExpenses) {
      await deleteExpenses(idList);
      return;
    }

    const newList = expenses.filter(({ id }) => !idList.includes(id));
    await updateExpenses?.(newList);
  };

  const sortedExpenses = [...expenses].sort(getExpenseItemSorter(locale));

  const tableButtons = [
    { onClick: handleDeleteItems, buttonText: translate.DELETE },
    ...(allowAdd ? [{ onClick: () => setIsModalOpen(true), buttonText: translate.ADD }] : []),
  ];

  return (
    <div className={displayColumn}>
      <EditableTable<ExpenseTableItem>
        columnTitleTranslations={columnTitleTranslations}
        editableList={EDITABLE_COLUMNS}
        itemList={sortedExpenses}
        isLoading={isLoading}
        buttonList={tableButtons}
        onChangeItem={handleChangeItem}
        showHeader
        title={tableTitle}
      />

      <CreateOfferExpenseModal
        expenses={expenses}
        updateExpenses={updateExpenses}
        possibleTemplates={possibleTemplates}
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
    </div>
  );
};

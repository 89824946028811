import {
  AssortedProductCard,
  Card,
  Heading,
  HeatPumpIndoorProductCard,
  HeatPumpOutdoorProductCard,
  SolarPanelProductCard,
  Text,
} from '~src/components/display';
import { BatteryProductCard } from '~src/components/display/ProductCard/BatteryProductCard';
import { InverterProductCard } from '~src/components/display/ProductCard/InverterProductCard';
import { useLocalization } from '~src/hooks';
import { useOfferSavings } from '~src/hooks/services';
import { mapOfferToOfferInput, mapResidenceToResidenceInput, Offer, Residence } from '~src/types';
import { formatCurrency } from '~src/utilities/format';

import { productSection, productText } from './ProductInformation.module.css';

type ProductInformationProps = {
  offer?: Pick<
    Offer,
    'price' | 'battery' | 'heatPumpIndoorUnit' | 'heatPumpOutdoorUnit' | 'inverter' | 'solarPanel' | 'assortedProducts'
  >;
  residence?: Residence;
};

export const ProductInformation = ({ offer, residence }: ProductInformationProps) => {
  const translate = useLocalization();

  const { savings } = useOfferSavings(mapResidenceToResidenceInput(residence), mapOfferToOfferInput(offer));

  const { saved } = savings ?? {};

  const { heatPumpIndoorUnit, heatPumpOutdoorUnit, solarPanel, inverter, battery, assortedProducts } = offer ?? {};

  return (
    <Card title={translate.PRODUCTS}>
      <div className={productSection}>
        <Heading className={productText} level={3}>
          {translate.PRICE_FOR_CUSTOMER}: {formatCurrency(offer?.price, { addVAT: true, decimals: 0 })}
        </Heading>

        {!!saved && (
          <Heading className={productText} level={3}>
            {translate.YEARLY_SAVINGS}: {formatCurrency(saved?.price, { decimals: 0 })}
          </Heading>
        )}
      </div>

      <div className={productSection}>
        {!!heatPumpOutdoorUnit && (
          <HeatPumpOutdoorProductCard
            showNotes
            key={heatPumpOutdoorUnit.id}
            product={heatPumpOutdoorUnit}
            residence={residence}
            type='display'
          />
        )}

        {!!heatPumpIndoorUnit && (
          <HeatPumpIndoorProductCard
            showNotes
            key={heatPumpIndoorUnit.id}
            product={heatPumpIndoorUnit}
            type='display'
          />
        )}

        {!!solarPanel && (
          <div key={solarPanel.id}>
            <Text>
              {translate.SOLAR_PANELS}: {solarPanel.quantity} {translate.PIECE_DENOMINATOR}
            </Text>

            <br />

            <SolarPanelProductCard showNotes product={solarPanel} type='display' />
          </div>
        )}

        {!!inverter && (
          <div key={inverter.id}>
            <Text>{translate.INVERTER}</Text>

            <br />

            <InverterProductCard showNotes product={inverter} type='display' />
          </div>
        )}

        {!!battery && (
          <div key={battery.id}>
            <Text>{translate.BATTERY}</Text>

            <br />

            <BatteryProductCard showNotes product={battery} type='display' />
          </div>
        )}

        {!!assortedProducts?.length && (
          <>
            <Text>{translate.ASSORTED_PRODUCTS}</Text>

            {assortedProducts?.map(product => (
              <div key={product.id}>
                <br />

                <AssortedProductCard product={product} quantity={product.quantity ?? 1} />
              </div>
            ))}
          </>
        )}
      </div>
    </Card>
  );
};

import { useLocalization } from '~src/hooks';
import { AssortedProduct } from '~src/types';

import { getProductDimensionString } from './productCardHelper';
import { ProductCardWithQuantity } from './ProductCardWithQuantity';

type AssortedProductCardProps = {
  product: AssortedProduct;
  quantity: number;
  handleIncrement?: () => void;
  handleDecrement?: () => void;
  handleDelete?: () => void;
};

export const AssortedProductCard = ({
  product,
  handleIncrement,
  handleDecrement,
  handleDelete,
  quantity,
}: AssortedProductCardProps) => {
  const translate = useLocalization();

  return (
    <ProductCardWithQuantity
      attributes={[getProductDimensionString(product.size, translate)]}
      brand={product.brand}
      model={product.model}
      imageUrl={product.imageUrl ?? undefined}
      price={product.price}
      id={product.id}
      handleIncrement={handleIncrement}
      handleDecrement={handleDecrement}
      handleDelete={handleDelete}
      quantity={quantity}
    />
  );
};

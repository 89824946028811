export const {
  VITE_ADMIN_URL: ADMIN_URL,
  VITE_API_URL: API_URL,
  VITE_ENV: ENV,
  VITE_HELP_CENTER_LINK:
    HELP_CENTER_LINK = 'https://bodilenergy.notion.site/bodilenergy/Bodil-hj-lpecenter-790140a40dd5425ba4df08dbef863847',
  VITE_LOGIN_URL: LOGIN_URL,
  VITE_SENTRY_DSN: SENTRY_DSN,
  VITE_TICKET_FORM_URL: TICKET_FORM_URL = 'https://forms.gle/hd7rqdaoDLC7npLv5',
  VITE_UPDATE_DELAY: UPDATE_DELAY = '333',
  VITE_SHOW_PROFIT_MARGINS: SHOW_PROFIT_MARGINS,
}: Record<string, string> = import.meta.env;

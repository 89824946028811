import { Tabs, TabsProps } from '~src/components/display';
import { useLocalization, useOfferProductState } from '~src/hooks';

import { CreateOfferAssortedProductTab } from './CreateOfferAssortedProductTab';
import { CreateOfferHeatPumpTab } from './CreateOfferHeatPumpTab';
import { CreateOfferSolarTab } from './CreateOfferSolarTab';

import { tabs } from './product.module.css';

export const CreateOfferProductTabs = () => {
  const translation = useLocalization();
  const { setOfferType, selectedOfferType } = useOfferProductState();

  const handleTabChange = (tabKey: string) => {
    switch (tabKey) {
      case 'solar': {
        setOfferType('solar');
        break;
      }
      case 'assorted': {
        setOfferType('assorted');
        break;
      }
      default: {
        setOfferType('heatPump');
        break;
      }
    }
  };

  const tabItemList: TabsProps['items'] = [
    {
      key: 'heatPump',
      label: translation.HEAT_PUMP,
      children: <CreateOfferHeatPumpTab />,
    },
    {
      key: 'solar',
      label: translation.SOLAR_CELL,
      children: <CreateOfferSolarTab />,
    },
    {
      key: 'assorted',
      label: translation.ASSORTED_PRODUCTS,
      children: <CreateOfferAssortedProductTab />,
    },
  ];

  return (
    <Tabs
      type='card'
      className={tabs}
      items={tabItemList}
      onChange={handleTabChange}
      defaultActiveKey={selectedOfferType}
      destroyInactiveTabPane
    />
  );
};

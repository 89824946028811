import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';

import { UPDATE_DELAY } from '~src/config';
import { useLocalization, useLocation } from '~src/hooks';
import { useSiteVisit, useUpdateSiteVisit, useUploadResidenceImages } from '~src/hooks/services';
import { Image, Residence, SiteVisitAnswer, SiteVisitQuestion } from '~src/types';
import { getPrimaryHeatingOptions, getSecondaryHeatingOptions } from '~src/utilities/heatingType';

import { SiteVisitSections } from '../utilities/siteVisitConstants';

export const useQuestionInformation = (residence?: Residence) => {
  const translate = useLocalization();

  const { parameters } = useLocation();

  const { accessCode } = parameters;

  const { id: residenceId, images } = residence ?? {};

  const { siteVisitChecklist, isLoading: isLoadingChecklist } = useSiteVisit(residence?.id);
  const { updateSiteVisitAnswerList, isLoading: isUpdatingAnswers } = useUpdateSiteVisit(residenceId ?? 0);
  const { uploadResidenceImages, isLoading: isUploadingImages } = useUploadResidenceImages(residenceId, accessCode);

  const heatSourceOptions = [...getPrimaryHeatingOptions(translate), ...getSecondaryHeatingOptions(translate)];
  const secondaryHeatSource = heatSourceOptions.find(
    ({ value }) => value === residence?.secondaryHeating?.fuelType
  )?.label;

  const handleUploadImages = (newImages: File[], category?: string) => uploadResidenceImages(newImages, category);

  const handleUpdate = debounce(
    async (question: SiteVisitQuestion, answer: string) => {
      if (!residenceId) {
        return;
      }

      await updateSiteVisitAnswerList([{ answer, residenceId, question }]);
    },
    Number(UPDATE_DELAY),
    { leading: true }
  );

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  const imageMap = groupBy(images, ({ category }) => category ?? 'other') as Record<SiteVisitSections, Image[]>;

  const isLoading = isLoadingChecklist || isUpdatingAnswers || isUploadingImages;

  return {
    answerMap,
    imageMap,
    isLoading,
    handleUpdate,
    handleUploadImages,
    isUploadingImages,
    secondaryHeatSource,
    isLoadingChecklist,
  };
};

import { useOfferProductState } from '~src/hooks';
import { useNewOfferExpenses } from '~src/hooks/services';
import { OfferProducts } from '~src/types';
import { findDefaultExpensesForOffer } from '~src/utilities/offer';

export const useCombineOfferProductsWithExpenses = (offerProducts: OfferProducts): OfferProducts => {
  const { newOfferExpenses } = useNewOfferExpenses();
  const { selectedOfferExpenses } = useOfferProductState();

  const defaultExpenses = findDefaultExpensesForOffer(offerProducts, newOfferExpenses);

  const expenses: OfferProducts['expenses'] = [...defaultExpenses, ...selectedOfferExpenses];

  return { ...offerProducts, expenses };
};

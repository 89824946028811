import { NavigateOptions, useNavigate } from 'react-router-dom';

import { ADMIN_URL, TICKET_FORM_URL } from '~src/config';
import { location } from '~src/utilities/global';

import { routes, RouteValue } from '../routes';
import { useToastMessage } from './useToastMessage';

export const useNavigation = () => {
  const routerNavigate = useNavigate();

  const navigate = (route: keyof typeof routes, options?: NavigateOptions) => routerNavigate(routes[route], options);

  const navigateBack = () => {
    routerNavigate(-1);
  };

  const navigateRaw = (route?: RouteValue | string, options?: NavigateOptions) =>
    routerNavigate(route ?? routes.root, options);

  const navigateToSiteVisit = (offerTag?: string) => {
    if (!window) {
      return;
    }

    const baseUrl = location.origin;

    window.open(`${baseUrl}/site-visit/${offerTag}`, '_blank', 'noopener noreferrer');
  };

  return { navigate, navigateBack, navigateRaw, navigateToSiteVisit };
};

export const useGlobalNavigation = () => {
  const { showMessage } = useToastMessage();

  const navigateToAdminPanel = () => {
    if (!ADMIN_URL) {
      showMessage({ type: 'warning', message: 'ADMIN_URL not defined in config.' });
      return;
    }

    location.href = ADMIN_URL;
  };

  const navigateToTicketForm = () => {
    if (!TICKET_FORM_URL) {
      showMessage({ type: 'warning', message: 'TICKET_FORM_URL not defined in config.' });
      return;
    }

    location.href = TICKET_FORM_URL;
  };

  return { navigateToAdminPanel, navigateToTicketForm };
};

import max from 'lodash/max';
import { useEffect, useState } from 'react';

import { Form, Input, Modal, Select } from '~src/components/display';
import { getValueAsInteger } from '~src/components/display/Form/formHelper';
import { useLocalization } from '~src/hooks';
import { OfferExpenseType } from '~src/types';
import { withVAT } from '~src/utilities/calculationHelper';

import { ExpenseTableItem, OfferExpenseTableProps } from './types';
import { useExpenseOptions } from './useExpenseOptions';

import { expenseModal, expenseModalForm } from './OfferExpenseTable.module.css';

const EXPENSE_KEY = 'id';
const QUANTITY_KEY = 'quantity';
const PRICE_KEY = 'price';
const BUY_PRICE_KEY = 'buyPrice';
const TITLE_KEY = 'title';
const TYPE_KEY = 'type';

const TITLE_MAX_LENGTH = 255;

const CUSTOM_EXPENSE_ID = -1;

type ExpenseFormData = {
  [EXPENSE_KEY]?: number;
  [QUANTITY_KEY]: number;
  [BUY_PRICE_KEY]: number;
  [PRICE_KEY]: number;
  [TITLE_KEY]?: string;
  [TYPE_KEY]?: OfferExpenseType;
};

type EditExpenseModalProps = Pick<OfferExpenseTableProps, 'possibleTemplates'> & {
  open?: boolean;
  setOpen: (open: boolean) => void;
  expenses?: ExpenseTableItem[];
  updateExpenses?: (expenses: ExpenseTableItem[]) => Promise<void>;
};

export const CreateOfferExpenseModal = ({
  open,
  setOpen,
  possibleTemplates,
  expenses = [],
  updateExpenses,
}: EditExpenseModalProps) => {
  const translate = useLocalization();
  const [form] = Form.useForm<ExpenseFormData>();

  const [isCustomExpense, setIsCustomerExpense] = useState(false);
  const [denominator, setDenominator] = useState(translate.PIECE_DENOMINATOR);

  const { expenseOptions: expenseOptionList } = useExpenseOptions(possibleTemplates, expenses);

  useEffect(function setPricesForExpenseTemplate() {});

  const handleSelectOption = (id: number) => {
    if (id === CUSTOM_EXPENSE_ID) {
      setIsCustomerExpense(true);
      setDenominator('');
      return;
    }

    const selectedExpense = possibleTemplates.find(expense => expense.id === id);

    setDenominator(selectedExpense?.denominator || translate.PIECE_DENOMINATOR);

    const { type, price, buyPrice } = selectedExpense ?? {};

    form.setFieldValue(TYPE_KEY, type?.toLowerCase().includes('installation') ? 'installation' : type);
    form.setFieldValue(PRICE_KEY, price ?? 0);
    form.setFieldValue(BUY_PRICE_KEY, buyPrice);
  };

  const handleAddItem = ({ id, buyPrice, price, quantity, title, type }: ExpenseFormData) => {
    const selectedTemplate = possibleTemplates.find(expense => expense.id === id);

    const newExpense: ExpenseTableItem = {
      id: (max(expenses?.map(expense => expense.id)) ?? 0) + 1,
      templateID: selectedTemplate?.id,
      title: selectedTemplate?.title ?? title ?? '',
      buyPrice,
      price,
      priceWithVAT: withVAT(price),
      quantity,
      type,
      notes: '',
    };

    const newList = [...expenses, newExpense];
    updateExpenses?.(newList);

    setIsCustomerExpense(false);
    setOpen(false);
  };

  const typeOptions = [
    { value: 'installation', label: translate.INSTALLATION },
    { value: 'nonPhysical', label: `${translate.EXPENSES} / ${translate.DISCOUNTS}` },
    { value: 'physical', label: translate.EXTRA_PARTS },
  ];

  return (
    <Modal
      className={expenseModal}
      onCancel={() => {
        form.resetFields();
        setIsCustomerExpense(false);
        setOpen(false);
      }}
      onOk={() => {
        form.submit();
      }}
      open={open}
      title={`${translate.ADD} ${translate.EXPENSE.toLowerCase()}`}
    >
      <Form
        form={form}
        layout='inline'
        className={expenseModalForm}
        initialValues={{ [QUANTITY_KEY]: 1 }}
        onFinish={handleAddItem}
      >
        {isCustomExpense ? (
          <Form.Item label={translate.TITLE} name={TITLE_KEY} rules={[{ required: true, max: TITLE_MAX_LENGTH }]}>
            <Input type='text' maxLength={TITLE_MAX_LENGTH} />
          </Form.Item>
        ) : (
          <Form.Item label={translate.TITLE} name={EXPENSE_KEY} rules={[{ required: true }]}>
            <Select options={expenseOptionList} onSelect={handleSelectOption} />
          </Form.Item>
        )}

        <Form.Item
          label={translate.PRICE_PER}
          name={QUANTITY_KEY}
          getValueFromEvent={getValueAsInteger}
          rules={[{ required: true }]}
        >
          <Input type='number' addonAfter={denominator} />
        </Form.Item>

        <Form.Item
          label={`${translate.BUY_PRICE} (${translate.WITHOUT_VATS})`}
          name={BUY_PRICE_KEY}
          getValueFromEvent={getValueAsInteger}
          rules={[{ required: true }]}
        >
          <Input type='number' addonAfter='DKK' />
        </Form.Item>

        <Form.Item
          label={`${translate.PRICE} (${translate.WITHOUT_VATS})`}
          name={PRICE_KEY}
          getValueFromEvent={getValueAsInteger}
          rules={[{ required: true }]}
        >
          <Input type='number' addonAfter='DKK' />
        </Form.Item>

        <Form.Item initialValue={expenses[0]?.type} label={translate.TYPE} name={TYPE_KEY} rules={[{ required: true }]}>
          <Select options={typeOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

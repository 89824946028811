import { Spinner } from '~src/components/display';
import { DefaultLayout } from '~src/components/layouts';
import { useCustomer, useOffer } from '~src/hooks/services';
import { useResidence } from '~src/hooks/services/useResidence';
import { useLocation } from '~src/hooks/useLocation';
import { findOfferValidProductType } from '~src/utilities/calculationHelper';

import { AssortedOfferPageContent } from '../features/offer/AssortedOfferPageContent';
import { OfferPageContent } from '../features/offer/OfferPageContent';

export const OfferPage = () => {
  const { parameters } = useLocation();
  const { offerId } = parameters;

  const { offer, isLoading: isLoadingOffer } = useOffer(Number(offerId));

  const { residence, isLoading: isLoadingResidence } = useResidence(offer?.residence?.id);

  const { customer, isLoading: isLoadingCustomer } = useCustomer(offer?.customer?.id);

  const offerType = findOfferValidProductType(offer);

  const isLoading = isLoadingOffer || isLoadingResidence || isLoadingCustomer;

  if (isLoading) {
    return (
      <DefaultLayout>
        <Spinner />
      </DefaultLayout>
    );
  }

  if (offerType === 'assorted') {
    return <AssortedOfferPageContent offer={offer} residence={residence} customer={customer} />;
  }

  return <OfferPageContent offer={offer} residence={residence} customer={customer} />;
};

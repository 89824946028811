import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';

import { useSiteVisit } from '~src/hooks/services';
import { Image, Residence, SiteVisitAnswer, SiteVisitQuestion } from '~src/types';

import { SiteVisitSections } from '../../siteVisit/utilities/siteVisitConstants';

export const useInstallationGuide = (residence?: Residence) => {
  const { siteVisitChecklist } = useSiteVisit(residence?.id);

  const { images } = residence ?? {};

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  const imageMap = groupBy(images, ({ category }) => category ?? 'other') as Record<SiteVisitSections, Image[]>;

  return { answerMap, imageMap };
};

import { useEffect } from 'react';

import { useCustomer } from '~src/hooks/services/useCustomer';
import { useResidence } from '~src/hooks/services/useResidence';
import { useLocation } from '~src/hooks/useLocation';
import { mapCustomerToCustomerInput, mapResidenceToResidenceInput } from '~src/types';

import { useCreateOfferState } from '../state/createOfferState';

export const useInitializeCreateOfferStateFromParameters = () => {
  const { customer, residence, setCustomer, setResidence } = useCreateOfferState();

  const { parameters } = useLocation();
  const { customerId, residenceId } = parameters;

  const { residence: initialResidence } = useResidence(Number(residenceId));
  const { customer: initialCustomer } = useCustomer(initialResidence?.customerID ?? Number(customerId));

  const selectedCustomerIsDesired = [Number(customerId), initialResidence?.customerID].includes(customer?.id);

  const shouldResidenceStateBeOverwritten = (!residence || residence.id !== Number(residenceId)) && initialResidence;
  const shouldCustomerStateBeOverwritten = (!customer || !selectedCustomerIsDesired) && initialCustomer;

  useEffect(
    function initializeStateFromParameters() {
      if (shouldResidenceStateBeOverwritten) {
        setResidence(mapResidenceToResidenceInput(initialResidence));
      }

      if (shouldCustomerStateBeOverwritten) {
        setCustomer(mapCustomerToCustomerInput(initialCustomer));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialCustomer, initialResidence]
  );
};

import { create } from 'zustand';

import { CustomerInput, MaybeId, Offer as OfferType, OfferExpenseInput, ResidenceInput } from '~src/types';

type Selectable = { selected: boolean };

type Customer = CustomerInput & MaybeId;

export type Offer = Pick<
  OfferType,
  'heatPumpIndoorUnit' | 'heatPumpOutdoorUnit' | 'solarPanel' | 'inverter' | 'battery' | 'assortedProducts'
> & {
  expenses: Array<OfferExpenseInput>;
} & Selectable &
  MaybeId;

type Residence = ResidenceInput & MaybeId;

type State = {
  customer?: Customer;
  crmCustomerAddress?: string;
  offerList: Array<Offer>;
  residence?: Residence;
};

type Actions = {
  addOffer: (offer: Offer) => void;

  removeOffer: (offerId: number) => void;

  reset: () => void;

  setCustomer: (customer?: Customer) => void;

  setCRMCustomerAddress: (address?: string) => void;

  setResidence: (residence?: Residence) => void;

  toggleOfferSelected: (offerId: number, selected: boolean) => void;
};

type CreateOfferState = State & Actions;

const initialState: State = {
  customer: undefined,
  crmCustomerAddress: undefined,
  offerList: [],
  residence: undefined,
};

export const useCreateOfferState = create<CreateOfferState>(set => ({
  ...initialState,

  addOffer: (offer: Offer) =>
    set(state => ({
      offerList: [...state.offerList, { ...offer, id: offer.id ?? getNextId(state.offerList), selected: true }],
    })),

  removeOffer: (offerId: number) =>
    set(state => ({ offerList: [...state.offerList.filter(({ id }) => id !== offerId)] })),

  reset: () => set(initialState),

  setCustomer: (customer?: Customer) => set({ customer }),

  setCRMCustomerAddress: (crmCustomerAddress?: string) => set({ crmCustomerAddress }),

  setResidence: (residence?: Residence) => set({ residence }),

  toggleOfferSelected: (offerId: number, selected: boolean) =>
    set(state => ({
      offerList: state.offerList.map(offer => (offer.id !== offerId ? offer : { ...offer, selected })),
    })),
}));

const getNextId = (list: Array<MaybeId>) => list.reduce((max, { id }) => Math.max(max, id ?? 0) + 1, 0);
